import React from 'react'
import RichTextEditor from './RichTextEditor'

const Admin = () => {
    return (
        <main>
            <section className='main-container'>
                <div className='admin-form'>
                    <form>

                    </form>
                    <RichTextEditor />
                </div>
            </section>
        </main>
    )
}

export default Admin